import EmbeddedScript from "../../../design-system/shared/components/embedded-script/embedded-script";
import GlobalFooter from '../../../design-system/global-footer/global-footer';
import GlobalFlatFooter from '../../../design-system/global-flat-footer/global-flat-footer';
import PageNotFoundModularBlock from '../../../design-system/page/page-not-found-modular-block/page-not-found-modular-block';
import SEO from '../../../design-system/shared/components/seo/seo';
import { AppDataContext } from '../../../design-system/shared/contexts/app-data/AppDataContext';
import useFeatureToggle from '../../../design-system/shared/hooks/use-feature-toggle/useFeatureToggle';
import {
  AppDataModel,
  GlobalAlertsContent,
  GlobalNavContent,
  GlobalSettings,
  GlobalFooterContent,
  IconPack,
  PageNotFoundContent,
  PrimaryHeaderContent,
  GlobalToolbarContent,
  RegionalLinksModalContent,
  GlobalFlatFooterContent
} from '../../../design-system/shared/models/app-data-model';
import StickyHeader from '../../../design-system/sticky-header/sticky-header';
import { graphql } from 'gatsby';
import React, { useContext, useEffect } from 'react';

export type PageNotFoundQueryResponse = {
  shPageNotFoundSpectrumhealthOrg: PageNotFoundContent;
  shSpectrumhealthOrgGlobalSettings: GlobalSettings;
  shSpectrumhealthOrgPrimaryHeader: PrimaryHeaderContent;
  shGlobalHeaderNav: GlobalNavContent;
  shSpectrumhealthOrgGlobalFooterNav: GlobalFooterContent;
  shGlobalAlertsSpectrumhealthOrg: GlobalAlertsContent;
  shGlobalToolbar: GlobalToolbarContent;
  shRegionalLinksModal: RegionalLinksModalContent;
  shGlobalFlatFooter?: GlobalFlatFooterContent;
};

export type NotFoundProps = {
  data: PageNotFoundQueryResponse;
};

const NotFound: React.FC<NotFoundProps> = (props: NotFoundProps) => {
  const context = useContext(AppDataContext);
  const staticIcons = props.data.shSpectrumhealthOrgGlobalSettings.site_theme.find((x) => x.icon_pack)?.icon_pack as IconPack[];
  const staticToggles = props.data.shSpectrumhealthOrgGlobalSettings.feature_toggles;
  const siteName = props.data.shSpectrumhealthOrgGlobalSettings.title;
  const siteUrl = props.data.shSpectrumhealthOrgGlobalSettings.prod_url;
  const useGlobalFlatFooter = useFeatureToggle('global-flat-footer', staticToggles);

  const embedScripts = context.embeddedScripts ? (
    <EmbeddedScript embedScriptBlocks={context.embeddedScripts} featureToggles={staticToggles} pagePath={"/404"} />
  ) : null;

  const modularBlocks = props.data?.shPageNotFoundSpectrumhealthOrg?.modular_blocks
    ? props.data.shPageNotFoundSpectrumhealthOrg.modular_blocks.map((item, index) => (
        <PageNotFoundModularBlock iconPack={staticIcons} blockContent={item} key={index} featureToggles={staticToggles} />
      ))
    : null;

  useEffect(() => {
    if (context.setAppData) {
      const appData: AppDataModel = {
        ...context.appData,
        globalSettings: props.data.shSpectrumhealthOrgGlobalSettings
      };
      context.setAppData(appData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const params = new URLSearchParams(window?.location.search);
    const uri = params.get('uri');
    if (uri) {
      history.replaceState({}, 'Page Not Found', decodeURI(uri));
    }
  }, []);

  /**
   * this hook sets the regional links modal data when we land on this page
   * */
  useEffect(() => {
    context.setRegionalLinksModalData?.(props.data.shRegionalLinksModal as RegionalLinksModalContent);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [context.setRegionalLinksModalData]);

  return (
    <>
      <StickyHeader
        featureToggles={props.data.shSpectrumhealthOrgGlobalSettings?.feature_toggles}
        globalNavContent={props?.data?.shGlobalHeaderNav}
        primaryHeaderContent={props?.data?.shSpectrumhealthOrgPrimaryHeader}
        staticIcons={staticIcons}
        campaignPageActive={false}
        title={props?.data?.shSpectrumhealthOrgGlobalSettings?.title}
        globalToolbarContent={props?.data?.shGlobalToolbar}
        corewellStyleOverrides={true}
      />
      <SEO title="Page Not Found" description={siteName} pagePath="404.html" noIndex={true} siteUrl={siteUrl} />
      {embedScripts}
      <div className="not-found page" id="page-content">
        <div className="modular-block-container">{modularBlocks}</div>
      </div>
      {useGlobalFlatFooter ? (
        <GlobalFlatFooter
          content={props.data?.shGlobalFlatFooter as GlobalFlatFooterContent}
          title={props.data?.shSpectrumhealthOrgGlobalSettings.title}
          copyrightSubtext={props.data?.shSpectrumhealthOrgGlobalSettings?.copyright_subtext}
          iconPack={staticIcons}
        />
      ) : (
        <GlobalFooter
          iconPack={staticIcons}
          content={props.data?.shSpectrumhealthOrgGlobalFooterNav as GlobalFooterContent}
          copyrightSubtext={props.data?.shSpectrumhealthOrgGlobalSettings?.copyright_subtext}
          title={props.data?.shSpectrumhealthOrgGlobalSettings.title}
        />
      )}
    </>
  );
};

export const PageNotFoundQuery = graphql`
  query {
    shGlobalHeaderNav {
      ...globalNavFragment
    }
    shPageNotFoundSpectrumhealthOrg {
      ...pageNotFoundFragment
    }
    shSpectrumhealthOrgGlobalSettings {
      ...globalSettingsFragment
    }
    shSpectrumhealthOrgPrimaryHeader {
      ...primaryHeaderFragment
    }
    shGlobalAlertsSpectrumhealthOrg {
      ...globalAlertsFragment
    }
    shSpectrumhealthOrgGlobalFooterNav {
      ...globalFooterFragment
    }
    shGlobalToolbar {
      ...globalToolbarFragment
    }
    shRegionalLinksModal {
      ...regionalLinksModalFragment
    }
    shGlobalFlatFooter {
      ...shGlobalFlatFooterFragment
    }
  }
`;

export default NotFound;
