import React from 'react';
import { Box } from 'rebass/styled-components';

import { layout } from '../../constants/constants';
import CallToAction from '../../shared/components/call-to-actions/call-to-action/call-to-action';
import ContentCardGrid from '../../shared/components/content-card-grid/content-card-grid';
import SecondaryNav from '../../shared/components/secondary-nav/secondary-nav';
import SingleColumnBlock from '../../shared/components/single-column-block/single-column-block';
import useColumnEnd from '../../shared/hooks/column-end/useColumnEnd';
import {
  FeatureToggle,
  IconPack,
  PageNotFoundBody,
  RegionalLinksModalContent,
  SecondaryNavContent
} from '../../shared/models/app-data-model';
import './page-not-found-modular-block.scss';

export type PageNotFoundModularBlockProps = {
  blockContent: PageNotFoundBody;
  featureToggles: FeatureToggle[];
  iconPack: IconPack[];
  regionalLinksModalData?: RegionalLinksModalContent;
};

const PageNotFoundModularBlock: React.FC<PageNotFoundModularBlockProps> = ({
  blockContent,
  featureToggles,
  iconPack,
  regionalLinksModalData
}: PageNotFoundModularBlockProps) => {
  const nav = blockContent?.primary_nav_block?.reference?.find((el) => el.primary_nav)?.primary_nav as SecondaryNavContent[];
  const [navBlockColumns] = useColumnEnd(nav as SecondaryNavContent[]);

  return (
    <div className="page-not-found-modular-block">
      {blockContent.single_column_content_block && (
        <SingleColumnBlock 
          iconPack={iconPack} 
          column={blockContent.single_column_content_block} 
          featureToggles={featureToggles} 
          modularBlock={true} 
        />
      )}
      
      {blockContent.content_card_grid && (
        <ContentCardGrid 
          iconPack={iconPack} 
          content={blockContent.content_card_grid} 
          featureToggles={featureToggles} 
          modularBlock={true}
        />
      )}

      {blockContent.primary_nav_block && navBlockColumns && (
        <Box marginX={'auto'} width={layout.singleColWidth}>
          <SecondaryNav columns={navBlockColumns} />
        </Box>
      )}

      {blockContent.low_impact_cta_block && (
        <CallToAction 
          iconPack={iconPack} 
          content={blockContent.low_impact_cta_block} 
          impact="low" 
          modularBlock={true} 
        />
      )}
    </div>
  );
};

export default PageNotFoundModularBlock;
